import React from 'react';
import "./WhyChooseUs.css"

const WhyChooseUs = () => {
    return (
        <section className="why-choose-us-section" width={350}>
            <h2>Why Choose Us</h2>
            <div className="why-choose-us-grid">
                <div className="why-choose-us-card">
                    <img src="https://cdn.leonardo.ai/users/3703f240-a548-44f0-935d-bd2e35068390/generations/91607f50-1c43-488c-92ef-41e3b11ced65/Default_A_vibrant_and_modern_image_for_a_flexbanner_printing_s_0.jpg" alt="Quality" />
                    <h3>High Quality</h3>
                    <p>We provide the highest quality printing services with attention to detail and precision.</p>
                </div>
                <div className="why-choose-us-card">
                    <img src="https://cdn.leonardo.ai/users/3703f240-a548-44f0-935d-bd2e35068390/generations/9ad614bf-f27e-49bc-b9a1-cc6eb22a8123/Default_A_modern_stylish_and_professionallooking_image_showcas_3.jpg" alt="Service" />
                    <h3>Excellent Service</h3>
                    <p>Our customer service team is dedicated to ensuring your satisfaction every step of the way.</p>
                </div>
                <div className="why-choose-us-card">
                    <img src="https://cdn.leonardo.ai/users/3703f240-a548-44f0-935d-bd2e35068390/generations/c13830e3-1ea8-4bdb-8a08-6b1199933300/Default_A_vibrant_collage_of_great_value_images_featuring_a_mi_2.jpg" alt="Value" />
                    <h3>Great Value</h3>
                    <p>We offer competitive pricing without compromising on quality or service.</p>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs