import React, { useState } from 'react';
import './AdminDashboard.css';
import Sidebar from '../components/Sidebar';

const AdminDashboard = () => {
 // const navigate = useNavigate();
  const [selectedContent, setSelectedContent] = useState('Dashboard');

  // useEffect(() => {
  //   // Check if the user is authenticated
  //   const isAuthenticated = localStorage.getItem('isAuthenticated');

  //   if (!isAuthenticated) {
  //     // Redirect to login page if not authenticated
  //     navigate.push('/admin-login');
  //   }
  // }, [navigate]);

  return (
    <div className="admin-dashboard">
    
      <div className="admin-container">
        <Sidebar setSelectedContent={setSelectedContent} />
        <div className="content">
          {selectedContent === 'Dashboard' && <h2> Admin Dashboard</h2>}
      </div>
      </div>
      </div>    
  );
};

export default AdminDashboard;
