import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Sidebar.css'; // Custom styles

const Sidebar = () => {
  const [openSection, setOpenSection] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Initially open

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? '' : section);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="d-flex">
      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
        {/* Close/Open button on the sidebar */}
        <div className="sidebar-toggle-btn" onClick={toggleSidebar}>
          {isSidebarOpen ? '◀' : '▶'}
        </div>

        {/* Sidebar Content */}
        {isSidebarOpen && (
          <div className="sidebar-content">
            <ul className="list-group">
              <li className="list-group-item">
                {/* Dashboard Link */}
                <Link to className="btn w-100">Dashboard</Link>
              </li>

              {/* Customer Enquiries */}
              <li className="list-group-item">
                <button className="btn w-100" onClick={() => toggleSection('customerEnquiries')}>
                  Customer Enquiries
                </button>
                {openSection === 'customerEnquiries' && (
                  <ul className="list-group ms-3">
                    <li className="list-group-item">
                      <Link to="/admin/customer-enquiries">View Enquiries</Link>
                    </li>
                  </ul>
                )}
              </li>

              {/* Order Management */}
              <li className="list-group-item">
                <button className="btn w-100" onClick={() => toggleSection('orderManagement')}>
                  Order Management
                </button>
                {openSection === 'orderManagement' && (
                  <ul className="list-group ms-3">
                    <li className="list-group-item"><Link to="/admin/order-entry">Order Entry</Link></li>
                    <li className="list-group-item"><Link to="/admin/order-tracking">Order Tracking</Link></li>
                    <li className="list-group-item"><Link to="/admin/order-history">Order History</Link></li>
                    <li className="list-group-item"><Link to="/admin/customization-request">Customization Request</Link></li>
                  </ul>
                )}
              </li>

              {/* Customer Management */}
              <li className="list-group-item">
                <button className="btn w-100" onClick={() => toggleSection('customerManagement')}>
                  Customer Management
                </button>
                {openSection === 'customerManagement' && (
                  <ul className="list-group ms-3">
                    <li className="list-group-item"><Link to="/admin/profile-creation">Profile Creation</Link></li>
                    <li className="list-group-item"><Link to="/admin/profile-management">Profile Management</Link></li>
                    <li className="list-group-item"><Link to="/admin/contact-management">Contact Management</Link></li>
                    <li className="list-group-item"><Link to="/admin/order-history-overview">Order History Overview</Link></li>
                    <li className="list-group-item"><Link to="/admin/order-details">Order Details</Link></li>
                    <li className="list-group-item"><Link to="/admin/feedback-collection">Feedback Collection</Link></li>
                    <li className="list-group-item"><Link to="/admin/feedback-analysis">Feedback Analysis</Link></li>
                  </ul>
                )}
              </li>

              {/* Design and Artwork */}
              <li className="list-group-item">
                <button className="btn w-100" onClick={() => toggleSection('designAndArtwork')}>
                  Design and Artwork
                </button>
                {openSection === 'designAndArtwork' && (
                  <ul className="list-group ms-3">
                    <li className="list-group-item"><Link to="/admin/file-upload">File Upload</Link></li>
                  </ul>
                )}
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* Main content area */}
      <div className={`content-area ${isSidebarOpen ? 'content-open' : 'content-closed'} p-4`}>
        <Outlet />
      </div>
    </div>
  );
};

export default Sidebar;
