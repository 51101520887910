// src/components/Admin/CustomerManagement/FeedbackDashboard.js
import React from 'react';

const FeedbackDashboard = () => {
  // Example data for feedback submissions
  const feedbackData = [
    { id: 1, feedbackText: 'Great service!', rating: 5, submissionDate: '2024-08-09' },
    { id: 2, feedbackText: 'Could be better.', rating: 3, submissionDate: '2024-08-08' },
  ];

  return (
    <div className="feedback-dashboard">
      <h2>Feedback Dashboard</h2>
      <table>
        <thead>
          <tr>
            <th>Feedback Text</th>
            <th>Rating</th>
            <th>Submission Date</th>
          </tr>
        </thead>
        <tbody>
          {feedbackData.map((feedback) => (
            <tr key={feedback.id}>
              <td>{feedback.feedbackText}</td>
              <td>{feedback.rating}</td>
              <td>{feedback.submissionDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FeedbackDashboard;
