import React from 'react';
import "./CustomerReview.css"

const CustomerReview = () => {
    return (
        <section className="testimonials-section">
            <h2>What Our Customers Say</h2>
            <div className="testimonials-grid">
                <div className="testimonial-card">
                    <p>"The quality of the banners was excellent, and the service was prompt and professional."</p>
                    <img src="https://cdn.leonardo.ai/users/3ecfe25c-59de-4bca-b88e-acbc620ff9f9/generations/741ef42c-a2ff-4b89-98c9-ae007fdd0549/Default_Highresolution_detailed_portrait_of_a_young_adult_male_3.jpg" alt="Customer 1" />
                    <h3 className="customer-name"> Abhay Deshmukh</h3>
                </div>
                <div className="testimonial-card">
                    <p>"We were extremely satisfied with our custom signage. It was exactly what we needed!"</p>
                    <img src="https://cdn.leonardo.ai/users/3ecfe25c-59de-4bca-b88e-acbc620ff9f9/generations/d3eb275a-bf11-4c98-ad53-9f5cbca64d8a/Default_A_highly_detailed_waistup_identitysized_portrait_image_1.jpg" alt="Customer 2" />
                    <h3 className="customer-name"> liza zenath </h3>
                </div>
                <div className="testimonial-card">
                    <p>"Great service and quick turnaround. Highly recommend for all your printing needs."</p>
                    <img src="https://cdn.leonardo.ai/users/3ecfe25c-59de-4bca-b88e-acbc620ff9f9/generations/e7e74f11-04ae-4ec8-be3b-91772e234b60/Default_Highresolution_closeup_identitysized_portrait_of_a_mal_2.jpg" alt="Customer 3" />
                    <h3 className="customer-name"> Omkar Kadam</h3>
                </div>
            </div>
        </section>)
}

export default CustomerReview