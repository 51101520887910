import React from 'react';
import './FloatingButtons.css';
import { FaWhatsapp, FaPhoneAlt } from 'react-icons/fa';

const FloatingButtons = () => {
  return (
    <div className="floating-buttons">
      <a
        href="https://wa.me/1234567890"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
        aria-label="Chat on WhatsApp"
      >
        <FaWhatsapp />
      </a>
      <a href="tel:+1234567890" className="call-button" aria-label="Call us">
        <FaPhoneAlt />
      </a>
    </div>
  );
};

export default FloatingButtons;
