import React, { useState } from 'react';
import './FileUpload.css'; // Import the CSS file

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    // Simulate a file upload process
    setUploadStatus('File uploaded successfully!');
  };

  return (
    <div className="file-upload-container">
      <h2>Upload Design File</h2>
      <form onSubmit={handleUpload}>
        <div>
          <label htmlFor="file">Select File:</label>
          <input type="file" id="file" onChange={handleFileChange} required />
        </div>
        <div>
          <label>File Details:</label>
          <input
            type="text"
            value={file ? `${file.name} (${file.size} bytes)` : ''}
            readOnly
          />
        </div>
        <button type="submit">Upload</button>
      </form>
      {uploadStatus && <div className="upload-status">{uploadStatus}</div>}
    </div>
  );
};

export default FileUpload;
