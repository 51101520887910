// src/pages/Admin/CustomerProfiles/ProfileManagement.js
import React, { useState } from 'react';
import './ProfileManagement.css';

const ProfileManagement = () => {
  const [customerName, setCustomerName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [customerType, setCustomerType] = useState('');
  const [address, setAddress] = useState('');
  const [contactDetails, setContactDetails] = useState('');
  const [paymentTerms, setPaymentTerms] = useState('');

  const handleUpdate = (e) => {
    e.preventDefault();
    // Handle profile update logic here
    alert("Profile Updated Successfully!");
  };

  return (
    <div className="profile-management">
      <h2>Edit Profile</h2>
      <form onSubmit={handleUpdate}>
        <div>
          <label>Customer Name:</label>
          <input
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Business Name:</label>
          <input
            type="text"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
        </div>
        <div>
          <label>Customer Type:</label>
          <input
            type="text"
            value={customerType}
            onChange={(e) => setCustomerType(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Address:</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Contact Details:</label>
          <input
            type="text"
            value={contactDetails}
            onChange={(e) => setContactDetails(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Payment Terms:</label>
          <input
            type="text"
            value={paymentTerms}
            onChange={(e) => setPaymentTerms(e.target.value)}
            required
          />
        </div>
        <button type="submit">Update Profile</button>
      </form>
    </div>
  );
};

export default ProfileManagement;
