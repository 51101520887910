import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css'; // Optional for additional custom styles
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap styles
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap's JS bundle

const Navbar = () => {
  const location = useLocation();

  // Function to close the navbar on mobile after clicking a link
  const handleNavLinkClick = () => {
    // Scroll to top when a link is clicked
    window.scrollTo(0, 0);
    
    // Collapse the navbar in mobile view after clicking a link
    const navbarToggler = document.querySelector('.navbar-collapse');
    if (navbarToggler && navbarToggler.classList.contains('show')) {
      navbarToggler.classList.remove('show');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Ensures the page scrolls to top on route change
  }, [location.pathname]);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <Link to="/" className="navbar-logo navbar-brand" onClick={handleNavLinkClick}>
        <img src="https://www.logodesign.net/assets/images/new-ui/cb-logo/logo-06.png" alt="Logo" height="40" />
        Banner Printing
      </Link>

      {/* Toggler for mobile view */}
      <button
        className="navbar-toggler ms-auto"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      {/* Menu Items */}
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link to="/" className="nav-link" onClick={handleNavLinkClick}>Home</Link>
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-link" onClick={handleNavLinkClick}>About Us</Link>
          </li>
          <li className="nav-item">
            <Link to="/services" className="nav-link" onClick={handleNavLinkClick}>Services</Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-link" onClick={handleNavLinkClick}>Contact Us</Link>
          </li>
          <li className="nav-item">
            <Link to="/blogs" className="nav-link" onClick={handleNavLinkClick}>Blogs</Link>
          </li>
          <li className="nav-item">
            <Link to="/admin-login" className="nav-link login-button" onClick={handleNavLinkClick}>Login</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;







// import React from 'react';
// import { Link } from 'react-router-dom';
// import './Navbar.css'; // Optional for additional custom styles
// import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap styles
// import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap's JS bundle


// const Navbar = () => {
//   return (
//     <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
//       <Link to="/" className="navbar-logo navbar-brand">
//         <img src="https://www.logodesign.net/assets/images/new-ui/cb-logo/logo-06.png" alt="Logo" height="40" />
//         Banner Printing
//       </Link>

//       {/* Toggler for mobile view */}
//       <button className="navbar-toggler ms-auto " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
//         <span className="navbar-toggler-icon"></span>
//       </button>

//       {/* Menu Items */}
//       <div className="collapse navbar-collapse" id="navbarNav">
//         <ul className="navbar-nav ml-auto">
//           <li className="nav-item ">
//             <Link to="/" className="nav-link">Home</Link>
//           </li>
//           <li className="nav-item">
//             <Link to="/about" className="nav-link">About Us</Link>
//           </li>
//           <li className="nav-item">
//             <Link to="/services" className="nav-link">Services</Link>
//           </li>
//           <li className="nav-item">
//             <Link to="/contact" className="nav-link">Contact Us</Link>
//           </li>
//           <li className="nav-item">
//             <Link to="/blogs" className="nav-link">Blogs</Link>
//           </li>
//           <li className="nav-item">
//             <Link to="/admin-login" className="nav-link login-button">Login</Link>
//           </li>
//         </ul>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;
