import React from 'react';
import "./AboutUsHome.css"

const AboutUsHome = () => {
    return (
        <main className="home-main">
            <section className="about-us-section">
                <h2>About Us</h2>
                <div className="about-us-grid">
                    <img src="/images/about-img.jpg" alt="About Us" className="about-us-card"  />
                    <div className="about-us-text">
                        <h3>Our Story</h3>
                        <p>
                            We are a leading digital banner printing shop with years of experience in providing top-notch printing services.
                            Our commitment to quality and customer satisfaction sets us apart. Our team of experts ensures every banner we produce
                            meets the highest standards of excellence. We use state-of-the-art printing technology to deliver vibrant, durable, and
                            eye-catching banners that help you stand out.
                        </p>
                        {/* <button className="about-button" onClick={navigateToAbout}>Learn More About Us</button> */}
                    </div>
                </div>
            </section>
        </main>
    )
}

export default AboutUsHome