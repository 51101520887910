import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <div className="services-container">
      <header className="services-header">
        <h1>Our Services</h1>
        <p>Explore the wide range of services we offer.</p>
      </header>
      <main className="services-main">

      <section className="banner-printing-section">
          <h2>Banner Printing</h2>
          <div className="banner-printing-grid">
            <img src="https://cdn.leonardo.ai/users/989a1a00-2398-47e7-b662-f19dd49860a1/generations/e5ead445-aabb-4952-806f-7e99727d233b/Default_A_futuristic_hightech_interior_of_a_modern_printing_sh_1.jpg?w=512" alt="Banner Printing" className="banner-printing-image" width={350} />
            <div className="banner-printing-text">
              <p>
                Our banner printing service offers high-quality, vibrant, and durable banners that are perfect for any occasion.
                Whether you need a banner for a corporate event, a trade show, or a personal celebration, we have you covered.
                Our state-of-the-art printing technology ensures that your banners will look stunning and last long.
              </p>
              <ul>
                <li>Custom sizes and designs</li>
                <li>High-quality materials</li>
                <li>Fast turnaround times</li>
                <li>Competitive pricing</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="poster-printing-section">
          <h2>Poster Printing</h2>
          <div className="poster-printing-grid">
            <img src="https://cdn.leonardo.ai/users/6ab14fce-0bfe-426b-aeee-8264f2b9177c/generations/64d88873-96f6-4442-8f55-edb6eca6f174/Default_A_vibrant_and_eyecatching_poster_design_optimized_for_3.jpg?w=512" alt="Poster Printing" className="poster-printing-image" width={350}/>
            <div className="poster-printing-text">
              <p>
                Our poster printing service provides high-resolution, eye-catching posters that are ideal for advertising, promotions, and personal use.
                From small to large sizes, our posters are printed with precision and vibrant colors to make your message stand out.
                Trust us to deliver posters that capture attention and communicate your message effectively.
              </p>
              <ul>
                <li>Customizable poster sizes and designs</li>
                <li>High-resolution printing</li>
                <li>Quick turnaround times</li>
                <li>Affordable rates</li>
              </ul>
            </div>
          </div>
        </section>
          
        <section className="custom-signage-section">
          <h2>Custom Signage</h2>
          <div className="custom-signage-grid">
            <img src="https://cdn.leonardo.ai/users/6ab14fce-0bfe-426b-aeee-8264f2b9177c/generations/3286c824-a226-437a-b98e-9b57f64fa386/Default_A_highangle_shot_of_a_vibrant_and_modern_custom_signag_3.jpg" alt="Custom Signage" className="custom-signage-image" width={350}/>
            <div className="custom-signage-text">
              <p>
                Our custom signage service offers tailored solutions for your business or personal needs. Whether you need indoor or outdoor signs, we create eye-catching and durable signage that stands out.
                From design to installation, we ensure your signs are perfect for your brand and message.
              </p>
              <ul>
                <li>Wide range of materials and finishes</li>
                <li>Custom sizes and designs</li>
                <li>Professional installation available</li>
                <li>Fast turnaround times</li>
                <li>Competitive pricing</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="vinyl-graphics-section">
          <h2>Vinyl Graphics</h2>
          <div className="vinyl-graphics-grid">
            <img src="https://cdn.leonardo.ai/users/6ab14fce-0bfe-426b-aeee-8264f2b9177c/generations/c13a6821-2b86-403c-9d54-51943f98903c/Default_A_vibrant_and_highcontrast_visual_showcasing_vinyl_gra_2.jpg" alt="Vinyl Graphics" className="vinyl-graphics-image" width={350}/>
            <div className="vinyl-graphics-text">
              <p>
                Our vinyl graphics service provides high-quality, custom vinyl solutions for your business or personal needs. 
                Whether you need vehicle wraps, window graphics, or wall decals, we offer vibrant, durable, and eye-catching vinyl graphics.
              </p>
              <ul>
                <li>Custom designs and sizes</li>
                <li>Durable and weather-resistant</li>
                <li>Professional installation</li>
                <li>Fast turnaround times</li>
                <li>Affordable rates</li>
              </ul>
            </div>
          </div>
        </section>

      </main>
    </div>
  );
};

export default Services;
