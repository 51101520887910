// src/pages/OrderTracking.js
import React, { useState } from 'react';
import './OrderTracking.css'; // Import the CSS file

const OrderTracking = () => {
  const [orderId, setOrderId] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [orders] = useState([]); // Sample orders data
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleFilter = () => {
    // Filtering logic
  };

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
  };

  return (
    <div className="order-tracking">
      <h2>Order Tracking Dashboard</h2>
      <form onSubmit={handleFilter}>
        <div>
          <label>Order ID:</label>
          <input
            type="text"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            placeholder="Enter Order ID"
          />
        </div>
        <div>
          <label>Status Filter:</label>
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="">All</option>
            <option value="pending">Pending</option>
            <option value="in-production">In Production</option>
            <option value="completed">Completed</option>
          </select>
        </div>
        <button type="submit">Filter</button>
      </form>

      <div className="orders-list">
        <h3>Orders List</h3>
        {orders.map((order) => (
          <div key={order.id} onClick={() => handleOrderClick(order)}>
            <p>Order ID: {order.id}</p>
            <p>Status: {order.status}</p>
          </div>
        ))}
      </div>

      {selectedOrder && (
        <div className="order-detail">
          <h3>Order Detail</h3>
          <p><strong>Order ID:</strong> {selectedOrder.id}</p>
          <p><strong>Status:</strong> {selectedOrder.status}</p>
        </div>
      )}
    </div>
  );
};

export default OrderTracking;
