import React from 'react';
import './Blogs.css';

const blogs = [
  {
    title: 'Top Tips for Designing Effective Banners',
    date: 'July 15, 2024',
    excerpt: 'Learn the best practices for designing banners that grab attention and convey your message effectively...',
    image: 'https://cdn.leonardo.ai/users/ac872c66-b013-4fac-bc53-ff24d593ae62/generations/1029869e-f2d5-4b72-a9e0-1a4679af1fc8/Default_A_trendy_and_modern_blog_webpage_on_a_sleek_silver_Mac_3.jpg',
  },
  {
    title: 'How to Choose the Right Material for Your Banner',
    date: 'August 5, 2024',
    excerpt: 'Selecting the right material for your banner is crucial for its durability and effectiveness. Here’s what you need to know...',
    image: 'https://cdn.leonardo.ai/users/ac872c66-b013-4fac-bc53-ff24d593ae62/generations/dd11830d-8970-4c8f-b0ce-a54c1b32cf13/Default_A_cluttered_yet_cozy_desk_with_a_laptop_open_to_a_blog_0.jpg',
  },
  {
    title: 'The Benefits of Custom Signage for Businesses',
    date: 'September 10, 2024',
    excerpt: 'Discover how custom signage can enhance your brand visibility and attract more customers...',
    image: 'https://cdn.leonardo.ai/users/ac872c66-b013-4fac-bc53-ff24d593ae62/generations/069c54e6-cf02-4389-a0f8-28a112152f1d/Default_A_vibrant_and_eclectic_collage_of_various_blogrelated_0.jpg',
  },
  {
    title: 'Why Vinyl Graphics Are Perfect for Advertising',
    date: 'October 12, 2024',
    excerpt: 'Vinyl graphics offer versatility and durability for your advertising needs. Here’s why they’re a great choice...',
    image: 'https://cdn.leonardo.ai/users/ac872c66-b013-4fac-bc53-ff24d593ae62/generations/24ed83c3-7003-4bb1-a7a0-35359dbe17fb/Default_A_vibrant_illustration_of_a_stylized_blogging_desk_set_3.jpg',
  },
];

const Blog = () => {
  return (
    <div className="blog-page">
      <h2>Our Latest Blogs</h2>
      <div className="blog-grid">
        {blogs.map((blog, index) => (
          <div key={index} className="blog-post">
            <img src={blog.image} alt={blog.title} />
            <div className="blog-post-content">
              <h3 className="blog-post-title">{blog.title}</h3>
              <p className="blog-post-date">{blog.date}</p>
              <p className="blog-post-excerpt">{blog.excerpt}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
