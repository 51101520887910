// src/components/Admin/CustomerProfiles/ContactManagement.js
import React, { useState } from 'react';
import './ContactManagement.css';

const ContactManagement = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [secondaryEmailAddress, setSecondaryEmailAddress] = useState('');
  const [preferredContactMethod, setPreferredContactMethod] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log('Contact Information Submitted', {
      phoneNumber,
      secondaryPhoneNumber,
      emailAddress,
      secondaryEmailAddress,
      preferredContactMethod,
    });
  };

  return (
    <div className="contact-management">
      <h2>Contact Management</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Phone Number:</label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Secondary Phone Number:</label>
          <input
            type="text"
            value={secondaryPhoneNumber}
            onChange={(e) => setSecondaryPhoneNumber(e.target.value)}
          />
        </div>
        <div>
          <label>Email Address:</label>
          <input
            type="email"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Secondary Email Address:</label>
          <input
            type="email"
            value={secondaryEmailAddress}
            onChange={(e) => setSecondaryEmailAddress(e.target.value)}
          />
        </div>
        <div>
          <label>Preferred Contact Method:</label>
          <select
            value={preferredContactMethod}
            onChange={(e) => setPreferredContactMethod(e.target.value)}
            required
          >
            <option value="">Select</option>
            <option value="email">Email</option>
            <option value="phone">Phone</option>
          </select>
        </div>
        <button type="submit">Save Contact Information</button>
      </form>
    </div>
  );
};

export default ContactManagement;
