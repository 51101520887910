// src/pages/OrderEntry.js
import React, { useState } from 'react';
import './OrderEntry.css';

const OrderEntry = () => {
  const [customerName, setCustomerName] = useState('');
  const [contactDetails, setContactDetails] = useState('');
  const [printSpecifications, setPrintSpecifications] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [summaryVisible, setSummaryVisible] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSummaryVisible(true);
  };

  return (
    <div className="order-entry">
      <h2>Create New Order</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label> Customer Name:</label>
          <input
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            required
          />
        </div>
        <div>
          <label> Contact Details:</label>
          <input
            type="text"
            value={contactDetails}
            onChange={(e) => setContactDetails(e.target.value)}
            required
          />
        </div>
        <div>
          <label> Print Specifications:</label>
          <textarea
            value={printSpecifications}
            onChange={(e) => setPrintSpecifications(e.target.value)}
            required
          />
        </div>
        <div>
          <label> Delivery Date:</label>
          <input
            type="date"
            value={deliveryDate}
            onChange={(e) => setDeliveryDate(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit Order</button>
      </form>

      {summaryVisible && (
        <div className="order-summary">
          <h3>Order Summary</h3>
          <p><strong>Customer Name:</strong> {customerName}</p>
          <p><strong>Contact Details:</strong> {contactDetails}</p>
          <p><strong>Print Specifications:</strong> {printSpecifications}</p>
          <p><strong>Delivery Date:</strong> {deliveryDate}</p>
        </div>
      )}
    </div>
  );
};

export default OrderEntry;
