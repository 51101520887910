import React, { useState } from 'react';
import './ContactUs.css';
import axiosInstance from '../AxiosInstance';
import Swal from 'sweetalert2';  // Import SweetAlert2

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Post form data
      const response = await axiosInstance.post('/backend/api/enquiries/submit', formData);
      console.log(response.data);

      // Display SweetAlert on success
      Swal.fire({
        title: 'Message Sent!',
        text: 'Your message has been sent successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });

      // Reset form after submission
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('There was an error submitting the form!', error);

      // Display SweetAlert on error
      Swal.fire({
        title: 'Error!',
        text: 'There was an error submitting your message. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  return (
    <div className="contact-us-page">
      <h2>Contact Us</h2>
      <div className="contact-us-grid">
        <div className="contact-details">
          <h3>Contact Details</h3>
          <p><strong>Phone:</strong> +123 456 7890</p>
          <p><strong>Email:</strong> info@bannerprinting.com</p>
          <p><strong>Address:</strong> 123 Main Street, Chinchwad, PUNE</p>
        </div>
        <div className="contact-form">
          <h3>Get in Touch</h3>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              required
            />
            <textarea
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              required
            ></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>
        <div className="contact-map">
          <h3>Our Location</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.019877946623!2d-122.08502938468165!3d37.42206577982567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb5b80bb10e7b%3A0xc2d62e7aef4e24e8!2sGoogleplex!5e0!3m2!1sen!2sus!4v1603923760372!5m2!1sen!2sus"
            title="Example Contact"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>
      <div className="social-media-icons">
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook"></i>
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-linkedin"></i>
        </a>
      </div>
    </div>
  );
};

export default ContactUs;
