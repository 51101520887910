import React from 'react';
import { useNavigate} from 'react-router-dom';
import './About.css';

const About = () => {
  const navigate = useNavigate();
  const navigateToContact = () => {
    navigate('/contact');
  };
  return (
    <div className="about-container">
      <header className="about-header">
        <h1>About Us</h1>
        <p>Learn more about our journey and what we stand for.</p>
      </header>
      <main className="about-main">
        <section className="our-story-section">
          <h2>Our Story</h2>
          <p>
            We are a leading digital banner printing shop with years of experience in providing top-notch printing services.
            Our commitment to quality and customer satisfaction sets us apart. Our team of experts ensures every banner we produce
            meets the highest standards of excellence. We use state-of-the-art printing technology to deliver vibrant, durable, and
            eye-catching banners that help you stand out.
          </p>
        </section>
        <section className="why-choose-us-section">
          <h2>Why Choose Our Printing Shop</h2>
          <div className="why-choose-us-grid">
            <img src="/images/about-img.jpg" alt="Why Choose Us" className="why-choose-us-image"  />
            {/* <img src="https://cdn.leonardo.ai/users/989a1a00-2398-47e7-b662-f19dd49860a1/generations/5ed7113e-b3d9-4d65-b27b-03ed72ab0944/Default_A_warm_and_inviting_print_shop_interior_with_large_flo_1.jpg?w=512" alt="Why Choose Us" className="why-choose-us-image"  /> */}
            <div className="why-choose-us-text">
              <p>
                At our printing shop, we prioritize customer satisfaction and quality above all else. Here’s why you should choose us:
              </p>
              <ul>
                <li>High-quality materials and printing technology</li>
                <li>Affordable pricing with no hidden costs</li>
                <li>Quick turnaround times to meet your deadlines</li>
                <li>Friendly and knowledgeable customer service</li>
                <li>Customized solutions to fit your unique needs</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="about-section">
          <h2>Our Mission</h2>
          <p>
            At our printing shop, our mission is to provide top-quality printing services that exceed our customers' expectations. We are committed to innovation, quality, and customer satisfaction. Our team of experts works tirelessly to ensure that every banner we produce meets the highest standards of excellence.
          </p>
        </section>
        <section className="about-section about-cta">
          <h2>Get Started with Your Banner Today!</h2>
          <p>
            Whether you need a banner for a special event, business promotion, or any other purpose, we are here to help. Contact us today to get started on your custom banner printing project!
          </p>
          <button className="cta-button" onClick={navigateToContact}>Contact Us</button>
        </section>
      </main>
    </div>
  );
};

export default About;
