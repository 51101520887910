import React from 'react'
import ContactUs from '../components/ContactUs'

const Contact = () => {
  // const [formData, setFormData] = useState({
  //   name: '',
  //   email: '',
  //   message: ''
  // });

  // // const handleChange = (e) => {
  // //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //    // console.log(formData);

  //      const response = await axios.post('http://localhost:8081/backend/api/enquiries/submit', formData);
  //      console.log(response.data);

  //     alert('Message sent successfully!');
  //     setFormData({ name: '', email: '', message: '' });  // Reset form after submission
  //   } catch (error) {
  //     console.error('There was an error submitting the form!', error);
  //   }
  // };

  return (
    <div>
      <ContactUs />
    </div>
  )
}

export default Contact