import axios from 'axios';

console.log('API Base URL:', process.env.REACT_APP_API_BASE_URL);

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});


// Add a request interceptor to include the JWT token in every request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        // Token has expired or is invalid
        localStorage.removeItem('token');
        window.location.href = '/login';  // Redirect to login
      }
      return Promise.reject(error);
    }
  );
  
export default axiosInstance;
