import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
// import Aboutus from "../assets/Images/Aboutus.jpg";
import '@fortawesome/fontawesome-free/css/all.min.css';
// import About from './About';
import HomeServices from '../components/HomeServices';
import CustomerReview from '../components/CustomerReview';
import WhyChooseUs from '../components/WhyChooseUs';
import AboutUsHome from '../components/AboutUsHome';
import DiscountOffer from '../components/DiscountOffer';
import ContactUs from '../components/ContactUs';




const Home = () => {

  const navigate = useNavigate();

  const navigateToAbout = () => {
    navigate('/about');
  };
  return (
    <div className="home-container-fluid">
      <div className="hero-section">
        <h1>Welcome to Digital Banner Printing</h1>
        <p>Your one-stop solution for high-quality banner printing services.</p>
        <button className="cta-button" onClick={navigateToAbout}>Learn More</button>

      </div>

      {/* Popular Products Section*/}

      <div className="popular-products">
        <h2>Popular Products</h2>
        <div className="products-list">
          <div className="product-item">
            <img src="https://printo-s3.dietpixels.net/cloudinary/res/dxivtqnri/image/upload/Large-Format-Banner/Large-banner/1666183881.jpg?quality=70&format=webp&w=640" alt="Product 1" />
            <h3> Large Format Banners</h3>
            <p>₹548.00 for 1 piece</p>
          </div>
          <div className="product-item">
            <img src="https://printo-s3.dietpixels.net/cloudinary/res/dxivtqnri/image/upload/Banners/Non-Tearable-Banner/1649557756.jpg?quality=70&format=webp&w=640" alt="Product 2" />
            <h3> Non-Terabble Banner</h3>
            <p>₹1050.00 for 1 piece</p>
          </div>
          <div className="product-item">
            <img src="https://printo-s3.dietpixels.net/cloudinary/res/dxivtqnri/image/upload/Banners/Fabric-Banner/1649557756.jpg?quality=70&format=webp&w=640" alt="Product 3" />
            <h3> Fabric Banners </h3>
            <p>₹648 for 1 piece</p>
          </div >

          {/* Related Product Section */}

          <div className="related-products">
            <h2>Related Products</h2>
            <div className="product-grid">
              <div className="product-card">
                <img src="https://printo-s3.dietpixels.net/site/20221212_161116603916_606b38_Standees.jpeg?quality=70&format=webp&w=640" alt="Product 4" />
                <h3> Standees </h3>

              </div>
              <div className="product-card">
                <img src="https://printo-s3.dietpixels.net/site/20221212_161404254096_88cca4_ASB.jpeg?quality=70&format=webp&w=640" alt="Product 5" />
                <h3> Acrylic Sign Board </h3>

              </div>
              <div className="product-card">
                <img src="https://printo-s3.dietpixels.net/site/20221212_161457114485_d7a23c_LFS.jpeg?quality=70&format=webp&w=640" alt="Product 6" />
                <h3> Large Format Sticker </h3>

              </div>


              {/* /*About us Section */}
              <AboutUsHome />

             

              {/* Our Sevices Section */}


              <HomeServices />

            </div>
            {/* Customer Review */}
          
            <CustomerReview />


            {/* Why Choose us */}

          
            <WhyChooseUs />

            {/* Discount offer */}
          
            <DiscountOffer />

            {/* Contact Us Section */}

            <ContactUs />

          </div>
        </div>

      </div>
    </div>

  );
};


export default Home;
