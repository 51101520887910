import React, { useEffect, useState } from 'react';
import './CustomerEnquiries.css';
import axiosInstance from '../../AxiosInstance';

const CustomerEnquiries = () => {
  const [enquiries, setEnquiries] = useState([]);

  useEffect(() => {
    // axios.get('http://localhost:8081/backend/api/enquiries/all')
    axiosInstance.get('/backend/api/enquiries/all')
      .then(response => {
        console.log('Fetched data:', response.data); // Log the data
        setEnquiries(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the enquiries!', error);
        console.log('Request URL:', process.env.REACT_APP_API_BASE_URL + '/backend/api/enquiries/all');
      });
  }, []);

  return (
    <div className="customer-enquiries-container">
      <div className="customer-enquiries-content">
        <h2>Customer Enquiries</h2>
        <table className="customer-enquiries-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            

            
            {enquiries.map((enquiry, index) => (
              <tr key={index}>
                <td>{enquiry.name}</td>
                <td>{enquiry.email}</td>
                <td>{enquiry.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerEnquiries;
