import React from 'react';
import "./DiscountOffer.css"

const DiscountOffer = () => {
    return (
        <section className="discount-offers-section">
            <h2>Discount Offers</h2>
            <div className="discount-grid">
                <div className="discount-card">
                    <h3>Summer Sale</h3>
                    <p>Get up to 50% off on all banner printing orders this summer.</p>
                </div>
                <div className="discount-card">
                    <h3>Bulk Order Discount</h3>
                    <p>Order more, save more. Get a 20% discount on orders of 10 banners or more.</p>
                </div>
                <div className="discount-card">
                    <h3>New Customer Offer</h3>
                    <p>First time ordering? Enjoy a 15% discount on your first purchase.</p>
                </div>
            </div>
        </section>
    )
}

export default DiscountOffer