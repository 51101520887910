import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AdminLogin.css';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Make API call to the backend for login
      const response = await axios.post('http://localhost:8081/backend/api/auth/login', {
        email: username,  // Match the field name with your backend (JwtRequest.java uses email)
        password: password,
      });

      // If login is successful, save the token in localStorage
      localStorage.setItem('token', response.data.jwtToken);

      // Redirect to the admin dashboard
      navigate('/admin');
    } catch (error) {
      // Handle error (invalid credentials, network error, etc.)
      setError('Invalid username or password');
      console.error('Error during login', error);
    }
  };

  return (
    <div className="login-container">
      <h2>Admin Login</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className="error">{error}</p>}
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default AdminLogin;
