// src/components/Admin/CustomerProfiles/ProfileCreation.js
import React, { useState } from 'react';
import './ProfileCreation.css'

const ProfileCreation = () => {
    const [customerName, setCustomerName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [customerType, setCustomerType] = useState('Individual');
    const [address, setAddress] = useState('');
    const [contactDetails, setContactDetails] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would handle the form submission
        console.log({
            customerName,
            businessName,
            customerType,
            address,
            contactDetails,
            paymentTerms,
        });
    };

    return (
        <div className="profile-creation">
            <h2>Create New Customer Profile</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Customer Name:</label>
                    <input
                        type="text"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Business Name:</label>
                    <input
                        type="text"
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                    />
                </div>
                <div>
                    <label>Customer Type:</label>
                    <select
                        value={customerType}
                        onChange={(e) => setCustomerType(e.target.value)}
                    >
                        <option value="Individual">Individual</option>
                        <option value="Business">Business</option>
                    </select>
                </div>
                <div>
                    <label>Address:</label>
                    <textarea
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Contact Details:</label>
                    <input
                        type="text"
                        value={contactDetails}
                        onChange={(e) => setContactDetails(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Payment Terms:</label>
                    <input
                        type="text"
                        value={paymentTerms}
                        onChange={(e) => setPaymentTerms(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Create Profile</button>
            </form>
        </div>
    );
};

export default ProfileCreation;
