// src/components/Admin/OrderManagement/OrderHistory.js
import React, { useState } from 'react';

const OrderHistory = () => {
  const [searchCriteria, setSearchCriteria] = useState('');
  const [orderHistory] = useState([
    // Mock data; replace with actual order data from backend
    { id: 1, customerName: 'John Doe', status: 'Completed', date: '2023-08-01' },
    { id: 2, customerName: 'Jane Smith', status: 'Pending', date: '2023-08-05' },
    // more orders...
  ]);
  const [filteredOrders, setFilteredOrders] = useState(orderHistory);

  const handleSearch = (e) => {
    const criteria = e.target.value;
    setSearchCriteria(criteria);
    const filtered = orderHistory.filter(order =>
      order.customerName.toLowerCase().includes(criteria.toLowerCase()) ||
      order.status.toLowerCase().includes(criteria.toLowerCase()) ||
      order.date.includes(criteria)
    );
    setFilteredOrders(filtered);
  };

  return (
    <div className="order-history">
      <h2>Order History</h2>
      <input
        type="text"
        placeholder="Search by customer name, status, or date"
        value={searchCriteria}
        onChange={handleSearch}
      />
      <div className="order-history-list">
        {filteredOrders.map(order => (
          <div key={order.id} className="order-history-item">
            <p><strong>Order ID:</strong> {order.id}</p>
            <p><strong>Customer Name:</strong> {order.customerName}</p>
            <p><strong>Status:</strong> {order.status}</p>
            <p><strong>Date:</strong> {order.date}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderHistory;
