import React, { useState } from 'react';
import './OrderDetails.css';

const OrderDetails = () => {
  const [orderId, setOrderId] = useState('');
  const [orderDetails] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    // Logic to fetch order details based on the orderId
    // Example: setOrderDetails('Sample order details fetched from backend');
  };

  return (
    <div className="order-details">
      <h2>Order Detail View</h2>
      <form onSubmit={handleSearch}>
        <div>
          <label>Order ID:</label>
          <input
            type="text"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            required
          />
        </div>
        <button type="submit">Search</button>
      </form>

      {orderDetails && (
        <div className="order-detail-view">
          <h3>Order Details</h3>
          <p>{orderDetails}</p>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
