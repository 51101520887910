import React, { useState } from 'react';
import './CustomizationRequest.css';

const CustomizationRequest = () => {
  const [description, setDescription] = useState('');
  const [ setAttachments] = useState(null);
  const [deadline, setDeadline] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    setSubmitted(true);
  };

  return (
    <div className="customization-request-container">
      <h2>Submit Customization Request</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Request Description:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Attachments:</label>
          <input
            type="file"
            onChange={(e) => setAttachments(e.target.files[0])}
          />
        </div>
        <div className="form-group">
          <label>Deadline:</label>
          <input
            type="date"
            value={deadline}
            onChange={(e) => setDeadline(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit Request</button>
      </form>

      {submitted && (
        <div className="submission-confirmation">
          <h3>Your request has been submitted successfully!</h3>
        </div>
      )}
    </div>
  );
};

export default CustomizationRequest;
