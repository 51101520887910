// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import Blogs from './pages/Blogs';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import FloatingButtons from './components/FloatingButtons';
import Sidebar from './components/Sidebar';
import OrderEntry from './components/Admin/OrderManagement/OrderEntry';
import OrderTracking from './components/Admin/OrderManagement/OrderTracking';
import OrderHistory from './components/Admin/OrderManagement/OrderHistory';
import CustomizationRequest from './components/Admin/OrderManagement/CustomizationRequest';
import ProfileCreation from './components/Admin/CustomerManagement/ProfileCreation';
import ProfileManagement from './components/Admin/CustomerManagement/ProfileManagement';
import ContactManagement from './components/Admin/CustomerManagement/ContactManagement';
import OrderHistoryOverview from './components/Admin/CustomerManagement/OrderHistoryOverview';
import OrderDetails from './components/Admin/CustomerManagement/OrderDetails';
import FeedbackForm from './components/Admin/CustomerManagement/FeedbackForm';
import FeedbackDashboard from './components/Admin/CustomerManagement/FeedbackDashboard';
import FeedbackAnalysisDashboard from './components/Admin/CustomerManagement/FeedbackAnalysisDashboard';
import FeedbackReports from './components/Admin/CustomerManagement/FeedbackReports';
import FileUpload from './components/Admin/DesignAndArtwork/FileUpload';
import CustomerEnquiries from './components/Admin/CustomerEnquiries';
import 'bootstrap/dist/css/bootstrap.min.css';

const Layout = ({ children }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <>
      {!isAdminRoute && <Navbar />}
      <main>{children}</main>
      {!isAdminRoute && <Footer />}
      {!isAdminRoute && <FloatingButtons />}
    </>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/about" element={<Layout><About /></Layout>} />
        <Route path="/services" element={<Layout><Services /></Layout>} />
        <Route path="/contact" element={<Layout><Contact /></Layout>} />
        <Route path="/blogs" element={<Layout><Blogs /></Layout>} />
        <Route path="/admin-login" element={<Layout><AdminLogin /></Layout>} />
        <Route path="/order" element={<Layout><OrderEntry /></Layout>} />



        {/* Admin routes */}
        <Route path="/admin/*" element={<Layout><Sidebar /></Layout>}>
          <Route path="dashboard" element={<AdminDashboard />} />
          {/* <Route path="" element={<PrivateRoute />}>
            <Route index element={<AdminDashboard />} />
          </Route> */}
          <Route path="customer-enquiries" element={<CustomerEnquiries />} />
          <Route path="order-entry" element={<OrderEntry />} />
          <Route path="order-tracking" element={<OrderTracking />} />
          <Route path="order-history" element={<OrderHistory />} />
          <Route path="customization-request" element={<CustomizationRequest />} />
          <Route path="profile-creation" element={<ProfileCreation />} />
          <Route path="profile-management" element={<ProfileManagement />} />
          <Route path="contact-management" element={<ContactManagement />} />
          <Route path="order-history-overview" element={<OrderHistoryOverview />} />
          <Route path="order-details" element={<OrderDetails />} />
          <Route path="feedback-collection" element={<FeedbackForm />} />
          <Route path="feedback-dashboard" element={<FeedbackDashboard />} />
          <Route path="feedback-analysis" element={<FeedbackAnalysisDashboard />} />
          <Route path="feedback-reports" element={<FeedbackReports />} />
          <Route path="file-upload" element={<FileUpload />} />



        </Route>
      </Routes>
    </Router>
  );
}

export default App;
