import React from 'react';
import "./HomeService.css"

const HomeServices = () => {
    return (
        <section className="services-section">
            <h2 className='h2_services'>Our Services</h2>
            <div className="services-grid">
                <div className="service-card">
                    <img src="https://cdn.leonardo.ai/users/6ab14fce-0bfe-426b-aeee-8264f2b9177c/generations/d473cecd-d14c-4b75-8664-4fa48211ad41/Default_A_vibrant_and_modern_digital_artwork_showcasing_a_vari_3.jpg?w=512" alt="Banner Printing" />
                    <h3>Banner Printing</h3>
                    <p>We offer high-quality banner printing services to help you promote your business effectively.</p>
                </div>
                <div className="service-card">
                    <img src="https://cdn.leonardo.ai/users/6ab14fce-0bfe-426b-aeee-8264f2b9177c/generations/6a99cdfa-3d9b-4cfc-bda9-6b77b1bc1dff/Default_A_vibrant_and_modern_illustration_of_a_bustling_citysc_2.jpg" alt="Poster Printing" />
                    <h3>Poster Printing</h3>
                    <p>Create stunning posters that capture attention and convey your message clearly.</p>
                </div>
                <div className="service-card">
                    <img src="https://cdn.leonardo.ai/users/6ab14fce-0bfe-426b-aeee-8264f2b9177c/generations/e6a3eac6-5c2b-444e-90d3-3f9ca6b85273/Default_A_stylized_illustration_of_a_custom_signage_showcasing_0.jpg?w=512" alt="Custom Signage" />
                    <h3>Custom Signage</h3>
                    <p>Get custom signage solutions tailored to your specific needs and preferences.</p>
                </div>
            </div>
        </section>
    )
}

export default HomeServices